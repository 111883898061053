$color-primary: #D13138;

// Link with arrow decoration
p.arrow_link {
    margin: 0;
    padding: 0;
}

p.arrow_link a {
    color: $color-primary;
    font-weight: 700;
}

p.arrow_link a::after {
    display: inline-block;
    margin-left: 10px;
    content:'\279C';
    text-decoration: none;
    transition: all 0.5s;
}

p.arrow_link a:hover::after {
    display: inline-block;
    margin-left: 20px;
}

p a.hero-button {
    margin-top: 1em;
    display: inline-block;
    background-color: #d63740;
    color: #fff;
    border-radius: 4px;
    font-size: 1.2rem;
    padding: .75rem 1.5rem;            

}

// Checklist bullet-points
.checklist {
    content:'';
    margin: 0;
    padding: 0;
}

.preamble {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
    line-height: 1.4;
}